import classNames from 'classnames';
import styles from './StickerButton.module.scss';
import { Link } from 'src/features/shared/components/link';
import { ILink } from 'src/features/shared/contentful/types/ILink';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import { IImage } from 'src/features/shared/contentful/types/IImage';

interface StickerButtonProps {
  image?: IImage;
  link?: ILink;
  visibleOn: 'mobile' | 'desktop';
  onClick?: () => void;
}

export const StickerButton = ({
  image,
  link,
  visibleOn,
  onClick,
}: StickerButtonProps) => {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = [breakpoints.xs.name, breakpoints.sm.name].includes(
    currentBreakpoint,
  );

  if (!image || !link) {
    return null;
  }

  return (
    <Link
      className={classNames(styles.displayNone, {
        [styles.mobileStickerButton]: visibleOn === 'mobile' && isMobile,
        [styles.desktopStickerButton]: visibleOn === 'desktop' && !isMobile,
      })}
      onClick={onClick}
      href={link.href}
    >
      <img src={image.src} alt={image.alt} className={styles.stickerButton} />
    </Link>
  );
};
