import { Asset, Entry, EntryFields } from 'contentful';
import { ILink, ILinkEntry } from 'src/features/shared/contentful/types/ILink';
import { IImage } from './IImage';
import { IUsp, IUspEntry } from './IUsp';

export enum PageHeaderBackgroundStyle {
  BackgroundImage = 'Background image',
  Collage = 'Collage',
  ColorYellow = 'Color Yellow',
  ColorSoftPeach = 'Color Soft-Peach',
  ColorSoftSand = 'Color Soft-Sand',
}

export interface IPageHeader {
  usps?: IUsp[];
  id?: string;
  type: 'pageHeader';
  title: string;
  subtitle?: string;
  primaryButton?: ILink;
  secondaryButton?: ILink;
  description: EntryFields.RichText;
  collageImages: IImage[];
  image: IImage;
  mobileImage?: IImage;
  hasFontColorLight: boolean;
  hasClubSearch: boolean;
  backgroundStyle: PageHeaderBackgroundStyle;
  mainImageSizes?: string;
  contentAlignment?: string;
}

export interface IPageHeaderFields {
  title: string;
  subtitle: string;
  primaryButton: ILinkEntry;
  primaryButtonLabel: string;
  secondaryButton: ILinkEntry;
  secondaryButtonLabel: string;
  description: EntryFields.RichText;
  backgroundStyle: PageHeaderBackgroundStyle;
  image: Asset;
  mobileImage: Asset;
  collageImages: Asset[];
  contentAlignment: string;
  hasClubSearch: boolean;
  hasFontColorLight: boolean;
  usps: IUspEntry[];
}

export interface IPageHeaderEntry extends Entry<IPageHeaderFields> {
  sys: {
    id: string;
    type: string;
    createdAt: string;
    updatedAt: string;
    locale: string;
    contentType: {
      sys: {
        id: 'pageHeader';
        linkType: 'ContentType';
        type: 'Link';
      };
    };
  };
}

export interface IPageHeaderEntry extends Entry<IPageHeaderFields> {
  sys: {
    id: string;
    type: string;
    createdAt: string;
    updatedAt: string;
    locale: string;
    contentType: {
      sys: {
        id: 'pageHeader';
        linkType: 'ContentType';
        type: 'Link';
      };
    };
  };
}
