import { Image } from '@features/shared/components/image';
import styles from './index.module.scss';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import { IImage } from 'src/features/shared/contentful/types/IImage';
import classNames from 'classnames';

export interface IImageCollageProps {
  images?: IImage[];
}

const topMobileImages = [
  '/static/images/sportcity/form-header-images/image1.png',
  '/static/images/sportcity/form-header-images/image2.png',
  '/static/images/sportcity/form-header-images/image3.png',
  '/static/images/sportcity/form-header-images/image4.png',
];

export function ImageCollage({ images }: IImageCollageProps) {
  const { currentBreakpoint, breakpoints } = useBreakpoints();

  const isSmall = [breakpoints.xs.name, breakpoints.sm.name].includes(
    currentBreakpoint,
  );
  if (!images || images.length !== 5) {
    console.warn('<FormHeaderImages /> must have exactly 5 images');
    return null;
  }

  return (
    <div className={styles.imageCollage}>
      <div className={styles.imageContent}>
        {isSmall &&
          topMobileImages
            .slice(0, 4)
            .map((imageSrc, index) => (
              <Image
                key={index}
                image={{
                  srcSet: imageSrc,
                  src: imageSrc,
                  alt: `image-${index + 1}`,
                }}
                className={classNames(
                  styles.topImage,
                  styles[`top-image-${index + 1}`],
                )}
              />
            ))}

        {images.map((image, index) => (
          <Image
            key={image.alt}
            image={image}
            className={styles[`image${index + 1}`]}
          />
        ))}
      </div>
    </div>
  );
}
